<template>
    <div>
        <p class="text text-margin" v-for="text in firstTexts" :key="text">
            {{ $t(text) }}
        </p>
        <p class="text" v-for="text in listTexts" :key="text">
            {{ $t(text) }}
        </p>
        <p class="text text-margin" v-for="text in lastTexts" :key="text">
            {{ $t(text) }}
        </p>
    </div>
</template>
<script>
export default {
    computed: {
        firstTexts() {
            return this.texts.slice(0, 2)
        },
        listTexts() {
            return this.texts.slice(2, 13)
        },
        lastTexts() {
            return this.texts.slice(13)
        }
    },
    data() {
        return {
            texts: [
                'faq.firestick.step23.theSettings', 
                'faq.firestick.step23.youCanFind',
                'faq.firestick.step23.list.theCurrent',
                'faq.firestick.step23.list.speedtest',
                'faq.firestick.step23.list.clearCache',
                'faq.firestick.step23.list.logout',
                'faq.firestick.step23.list.expiryDate',
                'faq.firestick.step23.list.timezone',
                'faq.firestick.step23.list.saveLogin',
                'faq.firestick.step23.list.categories',
                'faq.firestick.step23.list.timeFormat',
                'faq.firestick.step23.list.playerSettings',
                'faq.firestick.step23.list.defaultLanguage',
                'faq.firestick.step23.list.textSize',
                'faq.firestick.step23.addOrRemove',
                'faq.firestick.step23.enjoy'
            ]
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";

.white-text {
    color: white;
    font-weight: 700;
}
.text:last-child {
    margin-bottom: 0 !important;
}
</style>
